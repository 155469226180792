<template>
  <v-row wrap no-gutters>

    <v-col cols="12">
      <v-select
        :key="`${step.id}-service`"
        v-model="properties.service"
        :items="formattedServices"
        :label="$lang.labels.service"
        outlined
        dense
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        data-cy="messaging-service"
        :readonly="!canEdit"
        @change="handleChange('service', $event)"
      ></v-select>
    </v-col>

    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="MESSAGING"
      :properties="properties"
      step-label="messaging"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="messaging"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>

    <v-col v-if="properties.service === 'KAFKA'" cols="12">
      <v-text-field
        :key="`${step.id}-messageKey`"
        v-model="properties.config.messageKey"
        outlined
        dense
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        :label="$lang.labels.messageKey"
        :readonly="!canEdit"
        data-cy="messaging-messageKey"
      />
    </v-col>

    <v-col v-if="['JMS', 'RABBITMQ'].includes(properties.service)" cols="12">
      <v-select
        :key="`${step.id}-deliveryMode`"
        v-model="properties.config.deliveryMode"
        :items="deliveryModes"
        :label="$lang.labels.deliveryMode"
        outlined
        dense
        data-cy="messaging-deliveryMode"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-priority`"
        v-model="properties.config.priority"
        outlined
        dense
        :label="$lang.labels.priority"
        :readonly="!canEdit"
        data-cy="messaging-priority"
      />
      <v-text-field
        :key="`${step.id}-timeToLive`"
        v-model="properties.config.timeToLive"
        outlined
        dense
        :label="$lang.labels.timeToLive"
        :readonly="!canEdit"
        data-cy="messaging-timeToLive"
      />
    </v-col>

    <v-col v-if="['MQTT'].includes(properties.service)" cols="12">
      <v-text-field
        :key="`${step.id}-qos`"
        v-model="properties.config.qos"
        outlined
        dense
        :label="$lang.labels.qos"
        :readonly="!canEdit"
        data-cy="messaging-qos"
      />
      <v-checkbox
        :key="`${step.id}-retained`"
        v-model="properties.config.retained"
        dense
        data-cy="messaging-retained"
        :label="$lang.labels.retained"
      />
    </v-col>

    <v-col cols="12" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-message`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.message"
        step-label="messaging"
        :label="$lang.labels.message"
        required
        :readonly="!canEdit"
        @change="handleChange('message', $event)"
        @openEditor="$emit('openEditor', $event, 'MESSAGING',
                           { text: '', value: properties.message, parentType: 'message' }
        )"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  CredentialsSelect,
  TextInputWithEditorButton,
  ScriptEnginesSelect
} from './components'
import StepMixin from './StepMixin'

import { MESSAGING_SERVICE_TYPES } from './constants'
import { definitions } from '@/utils/definitions'

export default {
  name: 'MessagingStep',
  components: {
    CredentialsSelect,
    TextInputWithEditorButton,
    ScriptEnginesSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      deliveryModes: definitions.JmsMessagingStepConfig.properties.deliveryMode.enum,
      KafkaMessagingStepConfig: {
        messageKey: ''
      },
      JmsMessagingStepConfig: {
        deliveryMode: '',
        priority: '',
        timeToLive: ''
      },
      RabbitmqMessagingStepConfig: {
        basicProperties: {
          appId: '',
          bodySize: '',
          clusterId: '',
          contentEncoding: '',
          contentType: '',
          correlationId: '',
          deliveryMode: '',
          expiration: '',
          headers: {},
          messageId: '',
          priority: '',
          replyTo: '',
          timestamp: '',
          type: '',
          userId: '',
          classId: '',
          className: ''
        },
        mandatory: false
      },
      MQTTMessagingStepConfig: {
        qos: '',
        retained: false
      }
    }
  },
  computed: {
    formattedServices() {
      return MESSAGING_SERVICE_TYPES.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.service) this.properties.service = ''

    if (!this.properties.config) this.$set(this.properties, 'config', {})

    if (this.properties.service === 'KAFKA' && !this.properties.config.messageKey) {
      this.properties.config = this.KafkaMessagingStepConfig
    }
    if (['JMS', 'RABBITMQ'].includes(this.properties.service)) {
      if (this.properties.config.deliveryMode === undefined) {
        this.properties.config.deliveryMode = ''
      }
      if (this.properties.config.priority === undefined) {
        this.properties.config.priority = ''
      }
      if (this.properties.config.timeToLive === undefined) {
        this.properties.config.timeToLive = ''
      }
    }
  },
  methods: {
    handleChange(key, val) {
      if (key === 'service') this.onChangeMessageService()
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }
      this.$set(this.properties, key, val)
    },
    onChangeMessageService() {
      if (this.properties.service === 'JMS') {
        this.properties.config = this.JmsMessagingStepConfig
      }
      if (this.properties.service === 'KAFKA') {
        this.properties.config = this.KafkaMessagingStepConfig
      }
      if (this.properties.service === 'RABBITMQ') {
        this.properties.config = this.JmsMessagingStepConfig
      }
      if (this.properties.service === 'MQTT') {
        this.properties.config = this.MQTTMessagingStepConfig
      }
    }
  }
}
</script>
